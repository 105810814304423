import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import TripleColumnBlock from "@components/tripleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import ReactPlayer from 'react-player'

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Corporate Relocations - Jill Heineck - Websuasion Podcast"
        ogDescription="Jill is a Realtor, Writer, and Speaker. She is the president of Heineck and Company, a Keller Williams firm, specializing in Corporate Relocation."
        image="/images/podcast/season-one/jill-heineck.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
						<h1>Corporate Relocations with Jill Heineck</h1>
						<h3>Heineck &amp; Company</h3>
						<p>
							Jill is a Realtor, Writer, and Speaker. She is the president of Heineck &amp; Company, a Keller Williams firm, specializing in Corporate Relocation. Jill is also the host of the Customer Experience Radio Podcast inspired by the companies who make an impact by putting their customer's experience first.
						</p>
						<p>
			        <QuickLinkButton
			          content={"Heineck & Company"}
			          url={"https://www.heineckandcompany.com/"}
			          icon={"linkify"}
			        />
						</p>
						<p>
			        <QuickLinkButton
			          content={"Customer Experience Radio"}
			          url={"https://podcasts.apple.com/us/podcast/customer-experience-radio/id1471865110"}
			          icon={"linkify"}
			        />
						</p>
						<p>
							In this episode of Websuasion Conversation, we discuss corporate relocation, working with Keller Williams, how her marketing background is an asset to her firm, and her Customer Experience Radio podcast.
						</p>
					</div>
        }
        textRight={
          <div className="centered">
            <img src="/images/podcast/season-one/jill-heineck.jpg" className="rounded-square" alt="Investing In Your Team with Kristen Hendricks" width="300" />
					</div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        text={
	          <div>
							<h2>Listen Now</h2>
						  <AudioPlayer
						    src="https://websuasion-conversation.s3.amazonaws.com/WebsuasionConversationEpisode040-JillHeineck.mp3"
						  />
							<h3>Subscribe to the podcast</h3>
							<p>
								<a href="https://itunes.apple.com/us/podcast/websuasion-conversation/id1454464479" target="_blank">
									<img src="/images/podcast/apple-podcasts-150x150.png" className="icon" alt="Websuasion Conversasion on Apple Podcasts" width="50" />
								</a>
								<a href="https://open.spotify.com/show/521lym6rKJPoWQZbeUROuG" target="_blank">
									<img src="/images/podcast/spotify-150x150.png" className="icon" alt="Websuasion Conversasion on Spotify" width="50" />
								</a>
							</p>
							<p>
								Podcast RSS feed:<br /><br />

								<span className="red">https://websuasion.com/podcast.xml</span>
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Introduction</h2>
							<p>
								Jill tells us about growing up in Cape Cod and studying marketing at Simmons in Boston.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/hi-t-y_36Uo'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Moving To Atlanta</h2>
							<p>
								Jill tells us why she settled in Atlanta.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/hjJgCPka1MU'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Getting Started In Real Estate</h2>
							<p>
								Jill tells us how she transitioned from running a fitness practice to getting started in Real Estate.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/CSuglQFLWSk'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Becoming An Associate Broker</h2>
							<p>
								Jill discusses why she chose to get her license as an associate broker.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/kRqgFFYpgBU'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Attracting Talent To Atlanta</h2>
							<p>
								Jill explains the types of companies Heineck &amp; Company serve and how she helps them attract talent to Atlanta.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/FjDlAA5xTWo'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>A Wide Range Of Relocation Needs</h2>
							<p>
								Jill talks about how she works with a wide range of HR departments and relocation needs for her corporate clients.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/MtTN7oEgAx8'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Biggest Relocation Concerns for Attracting Employees</h2>
							<p>
								Jill tells us the biggest relocation concerns companies have when it comes to attracting talent.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/UoxDEZgyBYA'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Lifestyle Transition Experts</h2>
							<p>
								Jill talks about how she works with families in transition and how she works with estate attorneys and family attorneys.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/eGbQNsDvtqE'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Leukemia and Lymphoma Society</h2>
							<p>
								Jill tells us about her work with the Leukemia and Lymphoma Society.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/MQroYMlRmSw'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>CREW Atlanta Signature Cocktails</h2>
							<p>
								Jill discusses her work with CREW Atlanta to support women getting into the Real Estate industry.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/aPCudFQd2Dw'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Business Development Approach</h2>
							<p>
								Jill tell us what she does for business development for Heineck &amp; Company.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/X2vSJSl5vUc'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Customer Experience Radio and Mobility Magazine</h2>
							<p>
								Jill talks about writing for Mobility Magazine and her love of discussing the customer experience on her Customer Experience Radio podcast.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/sjnxURh2MMo'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>App Developer Checklist</h2>
							<p>
								You've been busy building your business, not learning all the ins and outs of application development. There's no reason you should know what it takes to develop a quality, maintainable application and bring it to market. But, before you contract with any firm (including ours), you should understand what is considered the standard. So, we've developed a handy two-page checklist. It will arm you the questions you need to ask before you move forward.
							</p>
						  <QuickLinkButton
						              content={"Get Your Copy"}
						              url={"/files/developer-checklist.pdf"}
						              icon={"file"}
						            />
	          </div>
	        }
	        textRight={
	          <div>
							<img src="/images/podcast/season-one/app-developer-checklist.png" className="rounded-image" alt="App Developer Checklist" width="620" />
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
